<template>
<el-dialog
  title="选择收货地址"
  :visible.sync="dialogVisible"
  width="600px"
  :before-close="handleClose"
  :destroy-on-close="true">
    <div v-for="(item,index) in list" :key="index" 
         :class="(choosed && item.id==choosed.id)?'address-card address-choosed':'address-card'"
         @click="choosed=item">
      <!-- <el-radio v-model="choosed" :label="item.id"></el-radio> -->
      <span class="address-check"><i class="el-icon-check"></i></span>
      <div>
        <div class="address-item"><label>收货人：</label>{{item.name}}<el-tag size="small" :class="item.defaultAddress?'address-default show':'address-default'">默认</el-tag></div>
        <div class="address-item"><label>收货电话：</label>{{item.phone}}</div>
        <div class="address-item"><label>收货地址：</label>{{item.city}}{{item.area}}{{item.detailedAddress}}</div>
      </div>
    </div>
    <el-button v-if="list.length < 5" style="width: 100%;" icon="el-icon-plus" @click="addressDialogVisible=true"> </el-button>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSelect">确 定</el-button>
    </div>

    <AddressEdit :dialogVisible.sync="addressDialogVisible" :address="editAddress" @refreshList="getData"></AddressEdit>
</el-dialog>
</template>
<script>
import {  getAddressList } from "@/api/user"

import AddressEdit from '@/components/shopping/AddressEdit' 

export default {
  name: 'AddressSelect',
  components: {
    AddressEdit
  },
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      list: [],
      choosed: null,
      addressDialogVisible: false,
      editAddress: {}
    }
  },
  created(){
    this.getData()
  },
  methods:{
    getData(){
      // this.list = [
      //   {id: 1, phone: 18580363923, name:'章三', address:"重庆市南岸区亚太商谷5栋24-11", defaultAddress: 1},
      //   {id: 2, phone: 18580363923, name:'李四', address:"重庆市南岸区亚太商谷5栋24-11", defaultAddress: 0},
      //   {id: 3, phone: 18580363923, name:'王武', address:"重庆市南岸区亚太商谷5栋24-11", defaultAddress: 0},
      //   {id: 4, phone: 18580363923, name:'哇哈哈', address:"重庆市南岸区亚太商谷5栋24-11", defaultAddress: 0},
      //   {id: 5, phone: 18580363923, name:'巴拉巴拉', address:"重庆市南岸区亚太商谷5栋24-11", defaultAddress: 0}
      // ]
      getAddressList().then(res => {
        if(res.success){
          this.list = res.result
          let da = this.list.filter(item => item.defaultAddress)
          if(da && da.length > 0) this.choosed = da[0]
          if(!this.choosed && this.list.length > 0) this.choosed = this.list[0]
        }
        else this.$message.error(res.message)
      })
    },
    handleSelect(){
      this.$emit('chooseAddress', this.choosed)
      this.handleClose()
    },
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
  }
}
</script>
<style lang="scss" scoped>
.address-card{
  border: 1px solid #ebeef5;
  padding: 15px;
  background: #ffffff;
  margin-bottom: 10px;
  // border-radius: 5px;
  transition: all linear .25s;
  cursor: pointer;
  position: relative;
}
.address-card:hover{
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  
}
.address-choosed{
  box-shadow: 0 2px 12px 0 rgb(64 158 255 / 10%);
   border: 1px solid rgb(64 158 255);
}
.address-item{
  color: #2f3032;
}
.address-item label{
  color: #606266;
}
.address-default{
  display: none;
  margin-left: 8px;
}
.address-default.show{
  display: inline-block;

}
.address-check{
  display: none;
  position: absolute;
  right: 0px;
  top: 0px;
  // width: 30px;
  // height: 30px;
  color: #ffffff;
  width: 0;
  height: 0;
  text-align: center;
  transition: all linear .25s;
  border-top: 35px solid #259bd5;
  border-left: 35px solid transparent;
}
.address-choosed .address-check{
  display: block;
}
.address-choosed  i{
  position: relative;
    top: -34px;
    right: 17px;
}

</style>