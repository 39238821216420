<template>
  <el-dialog
    class="address-edit-dialog"
    title="填写收货地址"
    :visible.sync="dialogVisible"
    width="700px"
    :before-close="handleClose"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <el-form
      ref="form"
      :model="address"
      :rules="rules"
      label-width="120px"
      label-position="left"
    >
      <el-form-item
        label="收货人姓名"
        prop="name"
      >
        <el-input v-model="address.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item
        label="收货人电话"
        prop="phone"
      >
        <el-input v-model="address.phone"  placeholder="请输入收货人电话号码"></el-input>
      </el-form-item>
      <el-form-item
        label="收货人地址"
        prop="city"
      >
        <el-cascader
          style="width: 100%"
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="handleChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="detailedAddress"
      >
        <el-input
          type="textarea"
          v-model="address.detailedAddress"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="设置为默认地址">
        <el-radio-group v-model="address.defaultAddress">
          <el-radio :label="true">默认</el-radio>
          <el-radio :label="false">非默认</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
        >确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { regionData, CodeToText } from "element-china-area-data"

import { getUserInfo, addAddress, editAddress } from "@/api/user"
import { sensitiveFilter } from '@/api/common'
const sensitiveValidator = (rule, value, callback) => {
  sensitiveFilter({ desc: value }).then(res => {
    if (!res.success) {
      callback(new Error(res.message));
    } else {
      callback();
    }
  })
}
export default {
  name: 'AddressEdit',
  components: {
  },
  props: {
    dialogVisible: Boolean,
    address: {
      type: Object,
      default: {
        name: '',
        phone: '',
        city: '',
        area: '',
        detailedAddress: '',
        defaultAddress: false,
        areaCode: ''
      }
    }
  },
  computed: {
    userInfo: function () {
      return getUserInfo();
    },
  },
  data() {
    return {
      modal: {
        name: '',
        phone: '',
        city: '',
        area: '',
        detailedAddress: '',
        defaultAddress: false,
        areaCode: ''
      },
      options: regionData,
      selectedOptions: [],
      rules: {
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          { validator: sensitiveValidator, trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        selectedOptions: [
          { required: true, message: '请选择收货地址', trigger: 'blur' },
          { validator: sensitiveValidator, trigger: 'change' }
        ],
        detailedAddress: [
          { required: true, message: '请输入地址详情', trigger: 'blur' },
          { max: 30, message: '长度在30个字以内', trigger: 'blur' },
          { validator: sensitiveValidator, trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择收货地址的所在省市', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    address: {
      handler(val) {
        //if(!val.id) return this.address = this.modal
        if (val.areaCode) this.selectedOptions = val.areaCode.split(',')
        else this.selectedOptions = []
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  methods: {
    getData() {
      //if(this.address.areaCode) this.selectedOptions = this.address.areaCode.split(',')
    },
    handleChange() {
      this.address.city = "";
      this.address.areaCode = this.selectedOptions.join(',')
      for (let i = 0; i < this.selectedOptions.length; i++) {
        if (i == 2) this.address.area = CodeToText[this.selectedOptions[i]];
        else this.address.city += CodeToText[this.selectedOptions[i]];
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.address.studentId = this.userInfo.id
          if (this.address.id) {
            editAddress(this.address).then(res => {
              if (res.success) {
                this.$message.success("添加成功")
                this.$emit('refreshList');
                this.handleClose()
              }
              else {
                this.$message.error(res.message)
              }
            })
          }
          else {
            addAddress(this.address).then(res => {
              if (res.success) {
                this.$message.success("添加成功")
                this.$emit('refreshList');
                this.handleClose()
              }
              else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })

    },
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
  }
}
</script>
<style lang="scss">
.address-edit-dialog{
  .el-dialog{
    height: 600px;
    border-radius: 10px;
    overflow: hidden;
    .el-dialog__header{
      padding: 0;
      background-image: url(~@/assets/image/title-banner.jpg);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-dialog__title{
        font-size: 24px;
        font-weight: bold;
        color: #fff;
      }
      .el-dialog__headerbtn{
          top: 20px;
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-dialog__close{
            font-size: 14px;
            font-weight:bold;
            color: #ccc;
          }
        }
    }
    .el-dialog__body{
      padding: 30px;
      .el-form-item{
        margin-bottom: 25px;
        .el-form-item__label::before{
          font-size: 24px;
          position: relative;
          top: 2px;
          color: #FF0000;
        }
        .el-radio{
          padding-right: 20px;
          color: #333;
          .el-radio__label{
            color: #333;
            font-weight: normal;
          }
          }
        }
        .el-radio.is-checked{
            .el-radio__label{
            color: #5C9DF8 ;
          }
      }
      .el-form-item:last-child{
        margin: 45px 0 0;
        .el-form-item__content{
          margin-left: 200px !important;
          .el-button{
            width: 100px;
            height: 45px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>